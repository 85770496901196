
            @import 'src/styles/mixins.scss';
        
.mainSection {
    background-color: #004056;
    background-image: url("/assets/images/homeownersImg.png");
    background-size: cover;
    background-position: right bottom;
    background-repeat: no-repeat;
    @include sm {
        background-size: contain;
        background-position: right;
    }
}
